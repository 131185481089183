import EngineGeneratorSearchView from '../views/search/EngineGeneratorSearch.js';
import EngineSearchView from '../views/search/EngineSearch.js';
import GeneratorSearchView from '../views/search/GeneratorSearch.js';
import HeatRecoveryOptionsView from '../views/search/HeatRecoveryOptions.js';
import HeatRecoveryOptions1View from '../views/search/HeatRecoveryOptions1.js';
import HeatRecoveryOptions2View from '../views/search/HeatRecoveryOptions2.js';
import GensetPerformanceSearch from '../views/search/GensetPerformanceSearch.js';
import EngineResults from '../views/search/EngineResults.js';
import GensetResults from '../views/search/GensetResults.js';
import SearchSubmit from '../views/search/SearchSubmit.js';

/**
 * Search controller.
 */
export default {

    /**
     * Display engine results after initial search.
     */
    engineResults() {
        new EngineResults({
            el: '#engineResults'
        }).render();
    },

    /**
     * Display engine / generator search view.
     */
    engineGenerator() {
        new EngineGeneratorSearchView({
            el: '#engineGeneratorSearch'
        }).render();
    },

    /**
     * Display engine / generator search view.
     */
    engine() {
        new EngineSearchView({
            el: '#engineGeneratorSearch'
        }).render();
    },
	
    /**
     * Display engine / generator search view.
     */
    generator() {
        new GeneratorSearchView({
            el: '#engineGeneratorSearch'
        }).render();
    },
	
    /**
     * Display heat recovery search options.
     */
    heatRecovery() {
        new HeatRecoveryOptionsView({
            el: '#heatRecoveryOptions'
        }).render();
    },

    /**
     * Display heat recovery search step 1 options.
     */
    heatRecovery1() {
        new HeatRecoveryOptions1View({
            el: '#heatRecoveryOptions'
        }).render();
    },
	
    /**
     * Display heat recovery search step 2 options.
     */
    heatRecovery2() {
        new HeatRecoveryOptions2View({
            el: '#heatRecoveryOptions'
        }).render();
    },
	
    /**
     * Display genset generated from matching engines
     * to generators during search.
     */
    gensetResults() {
        new GensetResults({
            el: '#gensetResults'
        }).render();
    },

    /**
     * Display a paginated list gensets with their performance
     * details.
     */
    gensetPerformance() {
        new GensetPerformanceSearch({
            el: '#gensetPerformance'
        }).render();
    },
	
	searchSubmit() {
        new SearchSubmit({
            el: '#engineGeneratorSearch'
        }).render();
	}
};
