import View from '../View.js';
import _ from '../../../vendor/lodash.js';

class HeatRecoveryOptions extends View {

    constructor(options) {
        super(_.defaultsDeep(options, {
            data: {
                engineCoolantFluid: 'water',
                prmCirFluid: 'water',
                secCirFluid: 'water'
            }
        }));
    }
}

export default HeatRecoveryOptions;