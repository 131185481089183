import View from '../View.js';
import _ from '../../../vendor/lodash.js';

class GensetResults extends View {

    /**
     * @param options
     */
    constructor(options) {
        super(_.defaultsDeep(options, {
            data: {
                checkAll: true
            }
        }));
    }

    /**
     * @override
     */
    methodNames() {
        return [
            'toggleAll',
            'toggleFilter'
        ];
    }

    /**
     * @param e
     */
    toggleAll(e) {
        this.checkAll = !this.checkAll;
    }

    //toggleFilter(e) {
    //    console.log('toggling filter');
    //    console.log($(e.target).data());
    //}
}

export default GensetResults;