import View from '../View.js';
import _ from '../../../vendor/lodash.js';
import $ from '../../../vendor/jquery.js';

var engineResults;

class EngineResults extends View {

    /**
     * @param options
     */
    constructor(options) {
        super(_.defaultsDeep(options, {
            data: {
                checkAll: true
            }
        }));

        engineResults = this;
    }

    ready() {
        $('.datatable').on('draw.dt', function () {
            engineResults.render();
        });
    }

    /**
     * @override
     */
    methodNames() {
        return [
            'toggleAll'
        ];
    }

    /**
     * @param e
     */
    toggleAll(e) {
        this.checkAll = !this.checkAll;
    }
}

export default EngineResults;