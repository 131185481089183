/**
 * Engine resource controller.
 */
export default {

    /**
     * On display all engines.
     */
    index() {
        //
    },

    /**
     * On show a single engine.
     *
     * @param params
     */
    show(params) {
        var id = params.id;
    }
};
