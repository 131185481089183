import View from '../View.js';
import _ from '../../../vendor/lodash.js';
import $ from '../../../vendor/jquery.js';

class EngineSearch extends View {

    constructor(options) {
        super(_.defaultsDeep(options, {
            data: {
                manufacturerAny: null,
                rpmAny: false,
                fuelAny: false,
                burnAny: false,
                voltageAny: false,
                optionsFound: 0,
                lean: false
            }
        }));
    }

    /**
     * @override
     */
    ready() {
        this.form = $('#engineGeneratorSearchForm');

        this.findOptionsFound();
    }

    /**
     * @override
     */
    methodNames() {
        return [
            'findOptionsFound',
            'checkAll',
            'onFindOptionsSuccess',
            'onFindOptionsError'
        ];
    }

    /**
     * Get the number of options found.
     */
    findOptionsFound() {
        var url = '/api/search/engine?' + (this.form.serialize() + '&count=1');

        this.optionsFound = 'loading...';

        this.$http.get(url, this.onFindOptionsSuccess).error(this.onFindOptionsError);
    }

    /**
     * Force all checkbox with the same name to be checked / unchecked depending
     * on the state of the clicked box.
     *
     * @param e
     */
    checkAll(e) {
        if(e.target.type === 'checkbox') {
            $('[name="' + e.target.name + '"]').each(function(index, box) {
                $(box).prop('checked', e.target.checked);
                $(box).attr('checked', e.target.checked);
            });
        }
    }

    /**
     * On `findOptionsFound` success.
     *
     * @param res
     * @param status
     */
    onFindOptionsSuccess(res, status) {
        this.optionsFound = res.engines;
    }

    /**
     * On `findOptionsFound` error.
     *
     * @param res
     * @param status
     */
    onFindOptionsError(res, status) {
        this.optionsFound = 0;
    }
};

export default EngineSearch;