import _ from '../../vendor/lodash.js';

class View {

    constructor(options) {
        this.options = _.assign({}, options);
    }

    /**
     * Render the Vue object.
     */
    render() {
        new Vue(_.assign(this.options, {
            ready: this.ready,
            methods: this.methods
        }));
    }

    /**
     * Return a string array of the methods
     * that will be passed to the Vue object.
     */
    methodNames() {
        return [];
    }

    /**
     * Called after the Vue object is initialized.
     */
    ready() {
        //
    }

    /**
     * Get Vue options.
     */
    get options() {
        return this.vueOptions;
    }

    /**
     * Set Vue options.
     *
     * @param options
     */
    set options(options) {
        this.vueOptions = options;
    }

    /**
     * Get Vue methods based on the methods()
     * methods.
     */
    get methods() {
        var self = this;
        var methods = {};

        this.methodNames().forEach(method => {
            methods[method] = self[method];
        });

        return methods;
    }
};

export default View;