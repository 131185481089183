import View from '../View.js';
import GensetShowView from './GensetShow.js';
import _ from '../../../vendor/lodash.js';
import $ from '../../../vendor/jquery.js';

class GensetPerformanceSearch extends View {

    constructor(options) {
        super(_.defaultsDeep(options, {
            data: {
                loading: false,
                currentIndex: 0
            }
        }));
    }

    /**
     * @override
     */
    ready() {
        this.gensets = $('#jsdata').data('gensets');
        this.showCurrentGenset();
    }

    /**
     * @override
     */
    methodNames() {
        return [
            'nextGenset',
            'prevGenset',
            'showCurrentGenset',
            'load'
        ];
    }

    /**
     * Show the next Genset model and update index.
     */
    nextGenset() {
        this.load(function(self) {
            self.currentIndex = Math.min(self.currentIndex + 1, self.gensets.length - 1);
            self.showCurrentGenset();
        });
    }

    /**
     * Show the prev Genset model and update the index.
     */
    prevGenset() {
        this.load(function(self) {
            self.currentIndex = Math.max(self.currentIndex - 1, 0);
            self.showCurrentGenset();
        });
    }

    /**
     * Show the current Genset based on the current index.
     */
    showCurrentGenset() {
        new GensetShowView({
            el: '#current-genset',
            templateDiv: '#genset-show-template',
            data: this.gensets[this.currentIndex]
        }).render();
    }

    /**
     * Set the view to loading until the db finishes.
     *
     * @param cb
     */
    load(cb) {
        this.loading = true;
        cb(this);
        this.loading = false;
    }
}

export default GensetPerformanceSearch;