import View from '../View.js';
import _ from '../../../vendor/lodash.js';
import $ from '../../../vendor/jquery.js';

class HeatRecoveryOptions1 extends View {

    constructor(options) {
        super(_.defaultsDeep(options, {
            data: {
                optionsFound: 0,
                engineCoolantFluid: 'water',
                prmCirFluid: 'water',
                secCirFluid: 'water'
            }
        }));
    }
	
    /**
     * @override
     */
    ready() {
        this.form = $('#heatRecoverySearchForm');

        this.findOptionsFound();
    }

    /**
     * @override
     */
    methodNames() {
        return [
            'findOptionsFound',
            'onFindOptionsSuccess',
            'onFindOptionsError'
        ];
    }

    /**
     * Get the number of options found.
     */
    findOptionsFound() {
        var url = '/api/search/genset?' + (this.form.serialize() + '&count=1');

        this.optionsFound = 'loading...';

        this.$http.get(url, this.onFindOptionsSuccess).error(this.onFindOptionsError);
    }

    /**
     * On `findOptionsFound` success.
     *
     * @param res
     * @param status
     */
    onFindOptionsSuccess(res, status) {
        this.optionsFound = res.engines;
    }

    /**
     * On `findOptionsFound` error.
     *
     * @param res
     * @param status
     */
    onFindOptionsError(res, status) {
        this.optionsFound = 0;
    }
}

export default HeatRecoveryOptions1;