import $ from '../../../vendor/jquery.js';

/**
 * Datatable helper.
 */
export default {

    /**
     * If this module has already been setup.
     *
     * @type {boolean}
     */
    setup: false,

    /**
     * Reference to current datatable object.
     *
     * @type {object}
     */
    datatable: null,

    /**
     * Datatable settings.
     *
     * @type {object}
     */
    settings: {
        dom: '<"top"i>rt<"bottom"flp><"clear">',
        pageLength: 20,
        bPaginate: false,

        language: {
            info:         '_START_ - _END_ of _TOTAL_ total',
            infoFiltered: '(_MAX_ unfiltered total)',

            paginate: {
                previous: '< Back',
                next:     'Next >'
            }
        },

        columnDefs: []
    },

    /**
     * Initialize all datatables on the view based on an input query.
     *
     * @param {object} table
     * @param {search} input
     */
    init(table, input) {
        this.datatable = $(table).DataTable(this.makeSettings($(table)));

        this.events();
        this.attachSearch(this.datatable, input);
    },

    /**
     * Setup event listeners.
     */
    events() {
        $('.datatable-toggle-filter').on('click', $.proxy(this.onToggleFilter, this));
    },

    /**
     * On triggering a toggle filter event, show the appropriate column and set the
     * toggle filter element to active.
     *
     * @param e
     */
    onToggleFilter(e) {
        var el = $(e.target);
        el.toggleClass('active');
        this.datatable.column(el.data('thIndex')).visible(el.hasClass('active'));
    },

    /**
     * Attach the data table search event to another input object.
     *
     * @param {object} table
     * @param {string} input
     */
    attachSearch(table, input) {
        $(input).on('keyup', function () {
            table.search($(this).val()).draw();
        });
    },

    /**
     * Generate DataTable settings.
     *
     * @param table
     * @returns {*}
     */
    makeSettings(table) {
        if(this.setup) {
            return this.settings;
        }

        this.makeSortableSettings(table);
        this.makeVisibleSettings(table);
        this.setup = true;

        return this.settings;
    },

    /**
     * Setup sortable / non sortable columns.
     *
     * @param table
     * @returns {object}
     */
    makeSortableSettings(table) {
        var sortSettings = {
            targets: [],
            orderable: false
        };

        table.find('.thead-main th').each((index, th) => {
            if(parseInt($(th).data('sortable')) === 0) {
                sortSettings.targets.push(index);
            } else if(!this.settings.order) {
                this.settings.order = [[index, 'asc']];
            }
        });

        this.settings.columnDefs.push(sortSettings);
    },

    /**
     * Setup visible / non visible columns.
     *
     * @param table
     * @returns {object}
     */
    makeVisibleSettings(table) {
        var visibleSettings = {
            "visible": false,
            "targets": []
        };

        table.find('.thead-main th').each((index, th) => {
            if(parseInt($(th).data('visible')) === 0) {
                visibleSettings.targets.push(index);
            }
        });

        this.settings.columnDefs.push(visibleSettings);
    }
};