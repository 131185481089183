import controllers from './controllers/all.js';
import datatable from './core/search/datatable.js';

/**
 * Application bootstrap controller.
 */
var app = {

    /**
     * Application routes.
     *
     * @type object
     */
    routes: {
        'search':                    controllers.search.engine,
        'search/submit':             controllers.search.searchSubmit,
        'search/generator':          controllers.search.generator,
        'search/engine-generator':   controllers.search.engineResults,
        'search/heat-recovery':      controllers.search.heatRecovery,
        'search/heat-recovery-1':    controllers.search.heatRecovery1,
        'search/heat-recovery-2':    controllers.search.heatRecovery2,
        'search/genset':             controllers.search.gensetResults,
        'search/genset/performance': controllers.search.gensetPerformance,
        'engine/{id}':               controllers.engine.show
    },

    /**
     * Run before any controller method is called.
     */
    before: () => {
        datatable.init('.datatable', 'input[type="search"].datatable-search');
    }
};

export default app;