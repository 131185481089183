import View from '../View.js';
import _ from '../../../vendor/lodash.js';
import $ from '../../../vendor/jquery.js';

class GensetShowView extends View {

    constructor(options) {
        super(_.defaultsDeep(options, {
            data: {
                has80: options.data.engine.manufacturer === 'Guascor',
                has75: options.data.engine.manufacturer !== 'Guascor',
                has60: options.data.engine.manufacturer === 'Guascor',
                has50: options.data.engine.manufacturer !== 'Guascor',
                has40: options.data.engine.manufacturer === 'Guascor'
            }
        }));

        this.setup(options.el, options.templateDiv);
    }

    /**
     * @override
     */
    ready() {
        this.template = $('#genset-show-template');
    }

    /**
     * Copy the genset show template over to the main el.
     */
    setup(el, templateDiv) {
        var $el = $(el);
        var $template = $(templateDiv);

        $el.html($template.html());
    }
}

export default GensetShowView;